import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout/layout";
import RichtextBlok from "../components/storyblok/richtext-blok";

const StaticPage = ({ data }) => {
  const page = JSON.parse(data?.page?.content ?? "");
  return (
    <Layout>
      <div className="ci-cont my-10">
        <h1 className="ci-h1 mb-4">{page.titolo}</h1>
        <RichtextBlok pClassName="mt-4" data={page.contenuto} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query StaticPage($id: String!) {
    page: storyblokEntry(id: { eq: $id }) {
      content
    }
  }
`;

export default StaticPage;
